@tailwind base;
@tailwind components;
@tailwind utilities;
/* @import url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap"); */
/* :root {
  --foreground-rgb: 0, 0, 0;
  --background-start-rgb: 214, 219, 220;
  --background-end-rgb: 255, 255, 255;
}

@media (prefers-color-scheme: dark) {
  :root {
    --foreground-rgb: 255, 255, 255;
    --background-start-rgb: 0, 0, 0;
    --background-end-rgb: 0, 0, 0;
  }
} */

body {
  color: rgb(var(--foreground-rgb));
  font-family: "Inter", sans-serif;
  overflow-x: hidden;
}
html {
  scroll-behavior: smooth;
}
@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}
/* Start Common Css */
header {
  max-width: 100% !important;
  /* background: none; */
}
.listItem a {
  color: #303030;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 26px; /* 185.714% */
  letter-spacing: 0.42px;
}
.searchInput {
  background: #fff !important;
  border-radius: 100px;
  border: 1px solid #bdbdbd;
  overflow: hidden;
  appearance: none;
}
.searchInput input::placeholder {
  color: #c0c0c0;
  font-size: 14px;
  font-weight: 300 !important;
}
.inputGroup {
  background: #fff;
}
.bgImgWrap {
  position: absolute;
  right: 0rem;
  bottom: auto;
  top: 10rem;
}
.bannerHeading {
  color: #fff !important;
}
.learnMore {
  position: absolute;
  bottom: 0px;
  z-index: 11;
  left: 0px;
  right: auto;
  background: #316bcb;
  color: #fff;
  padding: 29px 25px;
  border-radius: 100px;
  font-size: 16px;
  font-weight: 600;
}
.wavesShape {
  position: absolute;
  /* right: 0;
  bottom: 0; */
  z-index: 1;
}
.fourLineText {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
.twoLineText {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
.demandCardDesc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
}
.demandCardTitle {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
.shortDesc {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  color: black !important;
  -webkit-text-fill-color: black !important;
  -webkit-box-shadow: 0 0 0 1000px white inset !important;
  -webkit-background-clip: text !important;
  background-clip: text !important;
}
.scrollbarFormat::-webkit-scrollbar-thumb {
  background: #88888836;
}
.scrollbarFormat {
  scrollbar-width: thin; /* For Firefox */
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;
}

.scrollbarFormat::-webkit-scrollbar {
  width: 8px; /* Width of the scrollbar */
}

.scrollbarFormat::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.3); /* Custom thumb color */
  border-radius: 4px; /* Roundness of the scrollbar */
}

.scrollbarFormat::-webkit-scrollbar-track {
  background-color: rgba(0, 0, 0, 0.1); /* Custom track color */
}
.videoDiv video {
  height: 100%;
  object-fit: cover;
}
.crossIcons svg {
  width: 18px !important;
  height: 18px !important;
  stroke-width: 3;
  stroke: #000;
}
.crossIcons {
  right: 18px;
  top: 22px;
  padding: 0;
}
/* End Common Css */
/* MobileMenus */

/* Start Slider Css */
/* .demandCourses .swiper-button-prev, .reviewCarousel .swiper-button-prev
{
  left: 0;
} */
.lpTalks .swiper-button-prev {
  margin-left: 7rem;
}
.lpTalks .swiper-button-next {
  margin-right: 7rem;
}
.blogCarousel .swiper-button-prev {
  margin-left: 7rem;
}
.blogCarousel .swiper-button-next {
  margin-right: 7rem;
}
.blogCarousel .swiper-button-prev,
.blogCarousel .swiper-button-next {
  top: -90px;
}
.demandCourses .swiper-button-prev,
.reviewCarousel .swiper-button-prev,
.lpTalks .swiper-button-prev,
.blogCarousel .swiper-button-prev {
  background-image: url("../../../public/darkArrowIcon.svg");
  background-color: transparent;
  left: 0;
}
/* End Slider Css */
/*============ Start Hero Section ============*/
.heroHeading {
  width: 100%;
  max-width: 580px;
  text-align: center;
  margin: 30px 0;
}
.heroDescription {
  width: 100%;
  max-width: 500px;
  text-align: center;
}
/* src/Carousel.css */
.embla {
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
}

.embla__container {
  display: flex;
  gap: 1rem;
  padding: 0 2rem; /* Ensures padding for partial slides */
}

.embla__slide {
  position: relative;
  transition: transform 0.3s, opacity 0.3s;
  flex: 0 0 25%; /* Base width for all slides */
  opacity: 0.5;
  transform: scale(0.8);
}
.embla__slide.is-active {
  flex: 0 0 40%; /* Enlarges the active slide */
  opacity: 1;
  transform: scale(1);
}
.embla__slide__inner {
  overflow: hidden;
  border-radius: 20px; /* Ensuring the corners are rounded */
}
.embla__slide img {
  display: block;
  width: 100%;
  height: auto;
  border-radius: 20px; /* Ensuring the corners are rounded */
}
/* .heroWrapVect
{
  position: absolute;
  top: 0px;
  right: -2px;
  z-index: 10;
} */
.videoIcon {
  position: absolute;
  top: 0;
  right: 4px;
  cursor: pointer;
  z-index: 12;
}
input::placeholder {
  font-weight: 400 !important;
  color: #c0c0c0 !important;
}
/*============ End Hero Section ============*/
/*============ Start BrowseCate Section ============*/
.arrowImag {
  background: #000;
  width: 46px;
  height: 46px;
  padding: 8px;
  border-radius: 50px;
  position: absolute;
  right: 0px;
  bottom: 0%;
  cursor: pointer;
  z-index: 10;
}
.overlayContainer {
  position: relative;
}
.overlayContainer::after {
  position: absolute;
  content: "";
  z-index: 1;
  height: 100%;
  width: 100%;
  overflow: hidden;
  background: rgb(0 0 0 / 60%);
}
.afterHidden::after {
  display: none;
}
/*============ End BrowseCate Section ============*/
/*============ Start AboutUs Section ============*/
/* .aboutSec
{
  margin-bottom: 8rem;
  position: relative;
} */
/*============ End AboutUs Section ============*/
/*============ Start Accreditations Section ============*/
.accreditations {
  /* margin: 5rem 0 8.5rem 0; */
  background: #fff;
  position: relative;
}
.accreOverImg {
  position: absolute;
  right: -2px;
  bottom: -2.3px;
}
.accreditations .viewAllBtn {
  position: absolute;
  right: 3.7px;
  bottom: 3.7px;
}
.bgImg2Wrap {
  position: absolute;
  top: 40px;
  left: 0;
}
/*============ End Accreditations Section ============*/
.reviewCarousel {
  margin: 80px 0;
  max-width: 100%;
  width: 100%;
}
/*============ Start TalksCarousel Section ============*/
.spacing {
  margin: 0 7rem !important;
}
.talkContainer {
  border: 1px solid #000;
  padding: 16px;
  border-radius: 16px;
  background: #fff;
}
.talkDesc {
  margin-right: 72px !important;
  margin-bottom: 16px !important;
  color: #1a1414 !important;
}
.secondaryOverlap {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 11;
}
.bgImg3Wrap {
  position: absolute;
  right: 0;
  bottom: -9rem;
}
/*============ End TalksCarousel Section ============*/
/*============ Start Academy Section ============*/
.studentSatisfaction {
  /* background: url("../../../public/academy.png") !important; */
  background: url("../../../public/academy.webp") !important;
  background-size: cover !important;
  background-repeat: no-repeat !important;
}
/*============ End Academy Section ============*/
/*============ Start CourseDetails Page ============*/
.courseDetailsWrap {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 11;
}
/* .courseDetailPage .videoIcon {
  left: 0;
} */
/* .overviewMenus {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
} */
.overviewListing {
  display: flex;
  display: -webkit-inline-box;
  align-items: start;
  gap: 12px;
  margin-right: 20px;
}
/* Start Upcoming Section */
.sessionCard {
  border: 1px solid #000;
  border-radius: 24px;
  background: #fff;
  position: relative;
  z-index: 11;
  /* padding: 40px; */
}
.country {
  background: #fff;
  font-weight: 700;
  font-size: 16px;
  padding: 10px;
  border-radius: 8px;
  border: 1px solid #c4c4c4;
  /* appearance: none; */
}
.country:focus {
  border-color: #c4c4c4;
  outline: 0;
}
/* End Upcoming Section */
/* Start SessionDetails Page */
.sessionDetails {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  height: 507px;
  max-height: 100%;
  width: 100%;
  border-radius: 24px;
  padding: 60px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  color: #fff;
}
/* End SessionDetails Page */
/*============ End CourseDetails Page ============*/
/*============ Start OurBlog Section ============*/
.bannerOverlap {
  position: absolute;
  bottom: -2px;
  left: 0;
  right: 0;
  margin: 0 auto;
}
.blogListContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 40px;
  margin-bottom: 6rem;
}
.searchGroup {
  width: 530px;
  max-width: 100%;
}
.blogSearchGroup .searchInput {
  /* height: 57px; */
  padding: 0 24px;
}
.blogSearchGroup .searchIconWrap {
  background: #000;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  right: 0;
  position: absolute;
  border-radius: 100px;
  cursor: pointer;
}
/* .blogSearchGroup .searchIconWrap svg
{
  height: 30px;
  width: 30px;
} */
.blogFilter {
  border: 1px solid #c4c4c4;
  /* padding: 17px 27px; */
  border-radius: 100px;
  cursor: pointer;
}
/*============ End OurBlog Section ============*/
/*============ Start Contact Section ============*/
.bgImgWrapContact {
  position: absolute;
  bottom: -5rem;
  left: 0;
  right: auto;
}
/*============ End Contact Section ============*/
/*============ Start ITCourses Page ============*/
.bgYellowWrap {
  position: absolute;
  right: 0;
  bottom: 0;
  top: 4rem;
}
/*============ End ITCourses Page ============*/
/* Start Footer Css */
.footer {
  margin-top: auto;
  position: relative;
}
.socialMenus {
  margin-top: 25px;
}
.socialItems {
  display: inline-block;
  margin: 0 23px 0 0;
}
.addressIconsItems {
  display: flex;
  margin-top: 20px;
}
.addressIconsItems a {
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0.42px;
}
.addressIconsItems svg {
  margin-right: 12px;
}
/* End Footer Css */
/* MyCarousel.css */
/* .embla {
  overflow: hidden;
}

.embla__container {
  display: flex;
}

.embla__slide {
  min-width: 100%;
  position: relative;
  padding: 20px;
  box-sizing: border-box;
  background-color: #ccc;
  text-align: center;
  font-size: 24px;
} */
.waveWrap {
  position: absolute;
  right: 6rem;
  z-index: 111;
  top: 2rem;
  z-index: 11;
}
.browseBottomWrap {
  position: absolute;
  bottom: -7rem;
  left: 0;
  right: auto;
}
.contactInput {
  width: 100%;
  border-bottom: 1px solid #dcdcdc;
  padding-bottom: 6px;
  outline: 0;
  font-size: 12px;
  background: transparent;
}
.contactWrapShape {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: 1;
  stroke-width: 0.5px;
}
.topRightRound {
  position: absolute;
  top: 0;
  right: 0;
}
.bottomLeftRound {
  position: absolute;
  bottom: -1px;
  left: -1px;
  right: auto;
}
.curriculum .swiper-button-prev {
  left: 0px;
  background-color: #316bcb;
  border-color: #316bcb;
}
.curriculum .swiper-button-next {
  left: 70px;
  background-color: #316bcb;
  border-color: #316bcb;
}
.ourPeople .swiper-button-prev {
  left: 0px;
  background-color: #000;
  border-color: #000;
}
.ourPeople .swiper-button-next {
  left: 70px;
  background-color: #000;
  border-color: #000;
}
.swiper-button-prev,
.swiper-button-next {
  z-index: 1111;
  background-color: #000;
  border: 1px solid #000;
  top: 0;
  right: 0;
  width: 53px;
  height: 53px;
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  position: absolute;
  background-size: contain;
  background-repeat: no-repeat;
  border-radius: 50%;
  background-size: 24px 100%;
  display: flex;
  background-position: 50% 100%;
  align-items: center;
  justify-content: center;
  transition: all 0.5s;
}
.swiper-button-prev {
  background-image: url("../../../public/leftArrow.svg");
}
.swiper-button-next {
  background-image: url("../../../public/rightArrow.svg");
}
.certifcates .swiper-button-prev {
  right: 60px;
}
.swiper-button-prev:hover,
.swiper-button-next:hover {
  background-color: #444;
}
.courseTraineCarousel .swiper-button-prev {
  left: 14rem;
  right: auto;
}
.courseTraineCarousel .swiper-button-next {
  left: 18rem;
  right: auto;
}
.reviewSlider .swiper-button-prev,
.reviewSlider .swiper-button-next {
  top: 9rem;
}
.hidden-icon {
  display: none;
}
/* .placeholderContent
{
  font-weight: 600;
  color: #000;
  font-size: 16px;

} */
/* Hide scrollbar for Chrome, Safari, and Opera */
.hidden-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.hidden-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.bg-gradient-transparent {
  background: linear-gradient(to right, #1a1414, transparent, #1a1414);
}

/*============ Start OurVision Page ============*/
.ourVision {
  background-image: url("../../../public/vision.png");
  background-position: center;
  background-size: cover;
  height: 100%;
  width: 100%;
  position: relative;
  z-index: 11;
  overflow: hidden;
}
.ourVision::after {
  background: rgb(0 0 0 / 60%);
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0;
  top: 0;
  z-index: -1;
}
/*============ End OurVision Page ============*/

.caseStudyShape {
  transform: rotateX(180deg) rotateY(180deg);
}

.caseStudy .swiper-button-next {
  left: 60px;
}
.overlay {
  position: relative;
  overflow: hidden;
}
.overlay::after {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 35.5%,
    rgba(0, 0, 0, 0.8) 100%
  );
  position: absolute;
  bottom: 0;
  left: 0;
  content: "";
  height: 100%;
  width: 100%;
  border-radius: 16px;
}
/* Translation Css */
body[dir="rtl"] .sendLink {
  padding: 13px 22px;
}
/* Start MediaQuery */
@media (max-width: 1024px) {
  .customClass .swiper-slide .strokeChange {
    fill: #abe69e;
  }
  .paymentImage svg,
  .paymentImage img {
    /* gap: 6px; */
    width: 150px;
    height: 60px;
  }
}
@media (max-width: 768px) {
  .browseBottomWrap {
    bottom: -3rem;
    width: 140px;
    height: 140px;
  }
  .waveWrap {
    right: 3px;
    top: 30px;
  }
  .spacing {
    margin: 0 1.5rem !important;
  }
  .bannerOverlap {
    width: 34rem;
    max-width: 100%;
    bottom: -30px;
  }
  .caseStudy .swiper-button-next {
    right: 0;
    left: auto;
  }
  .caseStudy .swiper-button-prev {
    left: auto;
    right: 60px;
  }
  .caseStudyShape {
    transform: rotateX(180deg) rotateY(0deg);
    right: -1px;
    left: auto !important;
  }
}
@media (min-width: 640px) {
  body[dir="ltr"] .customClass .swiper-slide.swiper-slide-next .strokeChange {
    fill: #abe69e;
  }
  body[dir="rtl"] .customClass .swiper-slide.swiper-slide-active .strokeChange {
    fill: #abe69e;
  }
}
@media (max-width: 640px) {
  .bgImgWrap,
  .bgYellowWrap {
    right: -6rem;
    top: 22rem;
  }
  .swiper-button-prev,
  .swiper-button-next {
    width: 45px;
    height: 45px;
  }
  /* .swiper-button-prev,
  .swiper-button-next {
    display: none;
  } */
  .ourPeople .swiper-button-next,
  .ourPeople .swiper-button-prev,
  .certifcates .swiper-button-prev,
  .certifcates .swiper-button-next {
    display: block !important;
  }

  .accreditationSlider .swiper-wrapper {
    height: 100vh;
    max-height: 500px;
  }
  .courseDetailPage .videoIcon {
    left: auto;
    right: 0;
  }
  .courseDetailsWrap {
    left: auto;
    right: -1px;
    transform: rotate(90deg);
  }
  .reviewSlider .swiper-button-next,
  .reviewSlider .swiper-button-prev {
    display: none;
  }
  .lpTalks .swiper-button-prev,
  .blogCarousel .swiper-button-prev {
    margin-left: 1rem;
  }
  .lpTalks .swiper-button-next,
  .blogCarousel .swiper-button-next {
    margin-right: 1rem;
  }
  .demandCourses .swiper-button-prev,
  .demandCourses .swiper-button-next {
    top: -60px;
  }
  .demandCourses {
    margin-top: 40px;
  }
  .transforming {
    transform: rotateX(0deg) rotateY(180deg);
  }
}
/* End MediaQuery */
/* .accreOverImg {
  border: none;
  background: linear-gradient(135deg, #1f005c, #5b0060, #870160, #ac255e, #ca485c, #e16b5c, #f39060, #ffb56b) !important;
  --mask: 
    radial-gradient(20px at 75% 100%, #0000 98%, #000) 50% calc(100% - 20px) / 80px 100% repeat-x,
    radial-gradient(20px at 25% 50%, #000 99%, #0000 101%) bottom / 80px 40px repeat-x;
  -webkit-mask: var(--mask);
  mask: var(--mask);
} */
.b24-widget-button-wrapper {
  bottom: 60px !important;
  z-index: 22222 !important;
}
.autoComplete {
  border-bottom: 1px solid #e4e4e7;
  border-radius: 0;
}
.autoComplete .truncate {
  white-space: pre-wrap !important;
}
.data-\[justify\=start\]\:basis-0[data-justify="start"] {
  flex-basis: auto;
}

/* This targets any div that might wrap around the image */
.filledImageCard > div {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  /* Temporary, to see if this is applied */
}

/* Ensure the image is styled properly */
.filledImageCard > div > img {
  object-fit: cover !important;
  /* width: 100% !important;
  height: 100% !important; */
  border-radius: 32px;
}
.rightEnrollWrapperShapeSpan {
  transform: rotateX(180deg);
}

body[dir="rtl"] .blogs .blogCarousel .swiper-button-prev {
  left: 0;
  right: auto;
}
